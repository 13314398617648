import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

/**
 * Class to manage the handle the saving time period
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingStore {
  private _loading = signal(false);

  /**
   * Observable to listen and know if the app is loading
   */
  loading$ = toObservable(this._loading);
  loading = this._loading.asReadonly();

  /**
   * Start the loading process
   */
  start(): void {
    this._loading.set(true);
  }

  /**
   * Stop the loading process
   */
  stop(): void {
    this._loading.set(false);
  }
}
